/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from "./vuetify";
import pinia from "../store";
import router from "../router";
import auth0 from "./auth0";
import { loadSentry } from "./sentry";
import { registerGlobalComponents } from "./global";
// import OneSignalVuePlugin from "@onesignal/onesignal-vue3";

// Types
import type { App } from "vue";

export function registerPlugins(app: App) {
  // const oneSignalAppId = import.meta.env.VITE_ONESIGNAL_APP_ID;
  loadSentry(app, router);
  app.use(vuetify).use(router).use(pinia).use(auth0);
  registerGlobalComponents(app);
}
