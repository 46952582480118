import * as Sentry from "@sentry/vue";

export function loadSentry(app: any, router: any) {
  const environment = NODE_ENV;
  const release = APP_VERSION;
  const name = APP_NAME;
  const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
  Sentry.init({
    app,
    dsn: sentryDSN,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    autoSessionTracking: true,
    sendClientReports: true,
    enableTracing: true,
    logErrors: true,
    attachProps: true,
    trackComponents: true,
    hooks: ["mount", "update", "unmount"],
    sendDefaultPii: true,
    integrations: [
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [
          "api.production.liveswitch.com",
          "api.contact.liveswitch.com",
          "api.supbubble.com",
          /^https:\/\/api\.production\.liveswitch\.com/,
          /^https:\/\/api\.contact\.liveswitch\.com/,
          /^https:\/\/api\.supbubble\.com/,
          /^https:\/\/api\.testing\.liveswitch\.com/,
          /^https:\/\/api\.contact\.testing\.liveswitch\.com/,
          /^https:\/\/api\.contact\.staging\.liveswitch\.com/,
          /^https:\/\/api\.staging\.liveswitch\.com/,
          /^https:\/\/api\.demo\.liveswitch\.com/,
          /^https:\/\/api\.contact\.demo\.liveswitch\.com/,
          /^https:\/\/api\.contact\.liveswitch\.com/,
          /^https:\/\/api\.production\.liveswitch\.com/,
          /^https:\/\/api\.liveswitch\.com/,
          /^https:\/\/jake-api\.ngrok\.app/,
          /^https:\/\/jake-socket\.ngrok\.app/,
          /^https:\/\/ls-concierge-socket-staging\.herokuapp\.com/,
          /^https:\/\/ls-concierge-socket-develop\.herokuapp\.com/,
          /^https:\/\/socket\.concierge\.liveswitch\.com/,
        ],
        networkCaptureBodies: true,
      }),
      Sentry.extraErrorDataIntegration({ depth: 8 }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [[500, 599]],
      }),
      Sentry.reportingObserverIntegration(),
      Sentry.sessionTimingIntegration(),
    ],
    environment,
    release: `${name}@${release}`,
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.testing\.liveswitch\.com/,
      /^https:\/\/api\.contact\.testing\.liveswitch\.com/,
      /^https:\/\/api\.contact\.staging\.liveswitch\.com/,
      /^https:\/\/api\.staging\.liveswitch\.com/,
      /^https:\/\/api\.demo\.liveswitch\.com/,
      /^https:\/\/api\.contact\.demo\.liveswitch\.com/,
      /^https:\/\/api\.contact\.liveswitch\.com/,
      /^https:\/\/api\.production\.liveswitch\.com/,
      /^https:\/\/api\.supbubble\.com/,
      /^https:\/\/api\.liveswitch\.com/,
      /^https:\/\/jake-api\.ngrok\.app/,
      /^https:\/\/jake-socket\.ngrok\.app/,
      /^https:\/\/ls-concierge-socket-staging\.herokuapp\.com/,
      /^https:\/\/ls-concierge-socket-develop\.herokuapp\.com/,
      /^https:\/\/socket\.concierge\.liveswitch\.com/,
    ],
  });
}
